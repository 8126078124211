import { PageContext, PageHeaderModule } from '../lib/sanity-types'
import React from 'react'
import { DetailPage } from '../components/detail-page'
import { RichText } from '../lib/sanity-types/sanity'

const DataProtectionSite: React.FC<{
  pageContext: PageContext
}> = ({ pageContext }) => {
  const content = (pageContext.page.template.modules[1] as RichText).richText
  const title = (pageContext.page.template.modules[0] as PageHeaderModule).title
  return (
    <DetailPage
      related={null}
      seo={pageContext.page.seo}
      pageContext={pageContext}
      name={title}
      description={null}
      content={content}
    />
  )
}

export default DataProtectionSite
